<template>
  <v-container>
    <v-dialog v-model="dialog" :width="manageWidth">
      <v-card light clas="justify-center" rounded raised v-if="stepSelectType">
        <!-- TODO, switch inputs to the custom input components made in ticket #6894 -->
        <v-toolbar dark color="primary" flat>
          <v-card-title :style="styleCustomFont">
            Please select the tissue that you would like to Initiate Transport
          </v-card-title>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-form class="px-8">
            <v-radio-group v-model="tissue" mandatory column>
              <v-col>
                <v-radio color="black" value="Embryos" v-if="isEmbryos">
                  <template v-slot:label>
                    <div>
                      <strong class="black--text">Embryos</strong>
                    </div>
                  </template>
                </v-radio>
                <v-radio color="black" value="Eggs" v-if="isEggs">
                  <template v-slot:label>
                    <strong class="black--text">Eggs</strong>
                  </template>
                </v-radio>
                <v-radio color="black" value="Sperm" v-if="isSperm">
                  <template v-slot:label>
                    <strong class="black--text">Sperm</strong>
                  </template>
                </v-radio>
              </v-col>
            </v-radio-group>
          </v-form>
        </v-card-actions>
        <v-card-actions>
          <v-col md="2">
            <v-btn block dark color="error" @click="close">Cancel</v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" dark @click="selectTissue" v-on="on">
              Continue
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card
        light
        clas="justify-center"
        rounded
        raised
        v-if="!patientInfomationShow && !stepSelectType"
      >
        <!-- TODO, switch inputs to the custom input components made in ticket #6894 -->
        <v-toolbar dark color="primary" flat>
          <v-card-title :style="styleCustomFont">
            To Initiate Transport, please confirm choice below:
          </v-card-title>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-form class="px-8">
            <v-radio-group v-model="type" mandatory column>
              <v-row>
                <v-radio color="red" value="All">
                  <template v-slot:label>
                    <div>
                      I/We authorize the lab to initiate transport of
                      <strong class="red--text">ALL</strong>
                      tissues associated with
                      <strong class="red--text">
                        ALL FREEZE or RECEIVE DATES.
                      </strong>
                    </div>
                  </template>
                </v-radio>
                <v-radio color="red" value="Partial">
                  <template v-slot:label>
                    <div>
                      I/We authorize the lab to initiate transport of tissues
                      associated with
                      <strong class="red--text">
                        SPECIFIC FREEZE or RECEIVE DATES.
                      </strong>
                    </div>
                  </template>
                </v-radio>
              </v-row>
            </v-radio-group>
            <v-checkbox v-model="isAgree" color="red">
              <template v-slot:label>
                I AGREE TO THE DISCLAIMER THAT GOES HERE
              </template>
            </v-checkbox>
            <div>
              <p>
                This is a request to transport cryo preserved tissue, the Cryo
                Service Team will contact you to verify your identity before
                processing this request.
              </p>
            </div>
          </v-form>
        </v-card-actions>
        <v-card-actions>
          <v-col md="2">
            <v-btn
              block
              dark
              style="height: 48.4px; font-size: 14px"
              color="primary"
              @click="close"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col v-if="!this.partner">
            <v-btn
              style="height: 48.4px; font-size: 14px"
              color="primary"
              @click="showPartnerInformation"
              :disabled="!isAgree"
            >
              Continue
            </v-btn>
          </v-col>
          <v-col v-else>
            <VueLoadingButton
              class="button"
              @click.native="validatePartnerInformation"
              :loading="isLoading"
              :styled="isStyled"
              style="
                background-color: #1f6070;
                color: white;
                font-size: 14px;
                font-weight: 500;
              "
            >
              CONTINUE
            </VueLoadingButton>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card
        light
        clas="justify-center"
        rounded
        raised
        v-if="patientInfomationShow"
      >
        <!-- TODO, switch inputs to the custom input components made in ticket #6894 -->
        <v-toolbar dark color="primary" flat>
          <v-card-title :style="styleCustomFont">
            Please add your partner information
          </v-card-title>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-form style="width: 100%">
            <v-container>
              <v-row>
                <v-col cols="12" md="12" class="mr-0 ml-0">
                  <TextField
                    label="Full Name*"
                    v-model="name"
                    :rules="rules.name"
                    :disabled="isDisabled || noPartner"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="mr-0 ml-0">
                  <TextField
                    label="Email*"
                    v-model="email"
                    :rules="rules.email"
                    :disabled="isDisabled || noPartner"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="mr-0 ml-0">
                  <DateField
                    v-model="dateOfBirth"
                    label="Date of Birth*"
                    :rules="rules.dateOfBirth"
                    :disabled="loading || isDisabled || noPartner"
                  ></DateField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="mr-0 ml-0">
                  <v-checkbox color="red" v-model="noPartner">
                    <template v-slot:label>No Partner</template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-actions>
        <v-card-actions>
          <v-col md="2">
            <v-btn
              block
              dark
              color="error"
              style="height: 48.4px; font-size: 14px"
              @click="close"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col>
            <VueLoadingButton
              class="button"
              @click.native="validatePartnerInformation"
              :loading="isLoading"
              :styled="isStyled"
              style="
                background-color: #1f6070;
                color: white;
                font-size: 14px;
                font-weight: 500;
              "
            >
              CONTINUE
            </VueLoadingButton>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { api, auth, messages } from '../../sharedPlugin';
import DateField from '../../components/inputs/DateField';
import TextField from '../../components/inputs/TextField';
import device from '../../utils/device';
import VueLoadingButton from 'vue-loading-button';
const Swal = require('sweetalert2');
export default {
  components: { DateField, TextField, VueLoadingButton },
  emits: ['goBack'],
  props: ['isSperm', 'isEggs', 'isEmbryos'],
  data() {
    return {
      styleCustomFont:
        device() === 'mobile'
          ? {
              'font-size': '1.945vw'
            }
          : {
              'font-size': '1.2vw'
            },
      manageWidth: device() === 'mobile' ? '100%' : '55%',
      stepSelectType: true,
      partner: null,
      name: null,
      isLoading: false,
      isStyled: true,
      dateOfBirth: null,
      email: null,
      noPartner: false,
      rules: {
        name: [(v) => !!v || 'Name is required'],
        dateOfBirth: [
          (v) => !!v || 'Date of birth is required',
          (v) => new Date(v) < Date.now() || 'The date of birth must be valid'
        ],
        email: [
          (v) => !!v || 'Email is required',
          (v) => /.+@.+/.test(v) || 'Email must be valid'
        ]
      },
      patientInfomationShow: false,
      type: null,
      tissue: null,
      dialog: false,
      loading: false,
      confirmDialog: false,
      isAgree: false
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...messages.mapComputed(['messageTo', 'loading'])
  },
  methods: {
    ...messages.mapMethods(['setMessageTo', 'createConversationAsync']),
    async submitRequest() {
      let body = {
        pid: this.user.patientId,
        subject: `Request to transport Cryo preserved tissue (${this.tissue})`,
        type: 'Transport',
        tissue_type: this.tissue,
        sub_type: this.type,
        sent_date: new Date(),
        partner_pid: this.partner && this.partner.id ? this.partner.id : null,
        partner_name: this.partner && this.partner.id ? null : this.name,
        partner_email: this.partner && this.partner.id ? null : this.email,
        partner_dob: this.partner && this.partner.id ? null : this.dateOfBirth,
        status: 'Requested'
      };

      await api.PatientRequests.sendPatientRequest(body)
        .then((e) => {
          console.log(e);
          const patientRequestId = e && e.length && e[0].id ? e[0].id : null;
          this.sendMessage(patientRequestId);

          this.close();
          this.isLoading = false;
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            message: 'Unable submit request',
            color: 'red'
          });
          this.isLoading = false;
        })
        .finally(() => (this.loading = false));
    },
    sendMessage(patientRequestId) {
      let message = `New Request to transport Cryo preserved tissue (${this.tissue}) - ${this.type} - ${this.user.fullName} - ${this.user.patientId}`;

      if (!this.noPartner) {
        message = `New Request to transport Cryo preserved tissue (${this.tissue}) - ${this.type} - Partner (${this.name} - ${this.email} - ${this.dateOfBirth}) - ${this.user.fullName} -  ${this.user.patientId}`;
      }

      this.setMessageTo({
        ...this.messageTo,
        username: this.user.fullName,
        message: message,
        subject: `Request to transport`,
        groupId: 234,
        groupName: 'Cryo Services Team',
        patientRequestId
      });

      this.createConversationAsync().then(() => {
        Swal.fire({
          title: 'Request Received',
          text: 'The Cryo Service Team has received your request and will contact you to verify your identity before processing.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ok'
        }).then((result) => {
          this.name = null;
          this.email = null;
          this.dateOfBirth = null;
          this.stepSelectType = true;
          this.patientInfomationShow = false;
          this.stepSelectType = true;
          this.$emit('finished');
        });
      });
    },
    show() {
      this.dialog = true;
      this.getPartner();
    },
    close() {
      this.patientInfomationShow = false;
      this.dialog = false;
      this.stepSelectType = true;
      this.confirmDialog = false;
      this.isLoading = false;
    },
    selectTissue() {
      this.stepSelectType = false;
    },
    showPartnerInformation() {
      this.patientInfomationShow = true;
    },
    async getPartner() {
      this.loading = true;

      await api.PartnerConnect.getPartnersLink()
        .then((result) => {
          if (result && result.length > 0) {
            result.filter((item) => item.consent === 1 && item.isPartner === 1);

            if (result && result.length > 0) {
              this.partner = result[0];
              this.name = this.partner.name;
              this.dateOfBirth = this.partner.dob;
              this.email = this.partner.email;
              this.isDisabled = true;
            } else {
              this.isDisabled = false;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validatePartnerInformation() {
      this.isLoading = true;
      if ((this.name && this.dateOfBirth && this.email) || this.noPartner) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'After submitting a request for transport, the Cryo Service Team will contact you to verify your identity and process your request.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, Submit',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitRequest();
          }
        });
      } else {
        Swal.fire({
          title: 'Request Error',
          text: 'All partner data needs to be added',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ok'
        }).then((result) => {
          this.isLoading = false;
        });
      }
    }
  }
};
</script>
