<template>
  <v-card flat light>
    <v-snackbar v-model="showErro" :timeout="3000" color="error" top right>
      You can't delete your default card!
    </v-snackbar>
    <v-toolbar dark color="primary" flat>
      <v-card-title :style="styleCustomButton">
        Manage Payment Methods
      </v-card-title>
      <v-spacer />
      <v-btn icon @click="$emit('goBack')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text
      :key="keyListingCards"
      class="px-0 pb-0"
      v-if="listingCards && listingCards.length > 0"
    >
      <v-data-table
        :headers="currentHeaders"
        :items="listingCards"
        disable-pagination
        hide-default-footer
        disable-sort
      >
        <template v-slot:[`item.account`]="{ item }">
          {{ item.account | VMask('##XX XXXX XXXX ####') }}
        </template>
        <template v-slot:[`item.cardType`]="{ item }">
          {{ item.cardType }}
        </template>
        <template v-slot:[`item.expiry`]="{ item }">
          <p
            :style="
              validationExpirationDate(
                item.expiry.substr(0, 2),
                item.expiry.substr(2, 2)
              )
            "
          >
            {{ item.expiry.substr(0, 2) }}/{{ item.expiry.substr(2, 2) }}
          </p>
        </template>
        <template v-slot:[`item.is_default`]="{ item, index }">
          <v-checkbox
            class="grey--text align"
            :disabled="item.is_default"
            @click="setDefault(item, index)"
            v-model="item.is_default"
            :ripple="false"
          />
        </template>

        <template v-slot:[`item.option`]="{ item, index }">
          <v-btn icon @click="deleteCard(item, index)">
            <v-icon v-if="item.is_default">mdi-delete</v-icon>
            <v-icon v-else color="primary">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text
      class="px-0 pb-0"
      v-else
      style="display: flex; justify-content: center; margin-top: 40px"
    >
      <p
        style="
          font-size: 20px !important;
          font-weight: 400 !important;
          align-self: center;
          color: darkgray;
        "
      >
        Click on button to add a card!
      </p>
    </v-card-text>
    <v-card-actions class="mb-2">
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$emit('showCardInfo')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add payment method</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import { api } from '../../sharedPlugin';
import moment from 'moment';
import device from '../../utils/device';
export default {
  props: ['cards'],
  data() {
    return {
      styleCustomButton:
        device() === 'mobile'
          ? {
              'font-size': '4.5vw'
            }
          : {
              'font-size': '1.2vw'
            },
      listingCards: this.cards,
      keyListingCards: 0,
      showErro: false,
      defaultCard: null,
      currentHeaders: [
        { text: 'Card Number', value: 'account', align: 'start' },
        { text: 'Card Brand', value: 'cardType', align: 'start' },
        { text: 'Expiration Date', value: 'expiry', align: 'start' },
        { text: 'Default Card', value: 'is_default', align: 'start' },
        { text: 'Remove', value: 'option', align: 'start' }
      ]
    };
  },
  watch: {
    cards: {
      handler: function (val) {
        this.listingCards = val;
        this.defaultCard = this.listingCards.find((card) => card.is_default);
        this.keyListingCards = Math.random();
      },
      deep: true
    }
  },
  computed: {},
  mounted() {},
  methods: {
    validationExpirationDate(month, year) {
      let date1 = moment('01/' + month + '/' + year, 'DD/MM/YY').format(
        'YYMMDD'
      );

      let date2 = moment().format('YYMMDD');

      if (date2 > date1) {
        return 'font-size: 12px; margin: 0; padding: 0; color: red';
      }
      return 'font-size: 12px; margin: 0; padding: 0; color: black';
    },
    deleteCard(card, index) {
      if (card.is_default) {
        this.showErro = true;
        setTimeout(() => {
          this.showErro = false;
        }, 3000);
        return;
      }

      this.$modal.show({
        text: `Do you want to delete this card?`,
        buttons: [
          {
            text: 'Cancel',
            color: 'grey black--text',
            onAction: () => {
              return;
            }
          },
          {
            text: 'Yes',
            color: 'primary white--text',
            onAction: async () => {
              this.removeCard(card, index);
            }
          }
        ]
      });
    },
    updateCardDefault(card, index) {
      if (!this.defaultCard) {
        this.defaultCard = this.listingCards.find((card) => card.is_default);
      }

      const cardUpdate = {
        newDefault: card.id,
        lastDefault: this.defaultCard.id
      };

      return api.CardConnect.setDefaultCard(cardUpdate)
        .then((result) => {
          this.$store.commit('showSnackbar', {
            message: 'Card successfully updated',
            color: 'success'
          });

          let indexLastDefault = this.listingCards.findIndex(
            (card) => card.id === this.defaultCard.id
          );
          this.listingCards[index].is_default = true;
          this.listingCards[indexLastDefault].is_default = false;
          this.defaultCard = card;
          this.getCardConnectProfile();
        })
        .catch((error) => {
          this.$store.commit('showSnackbar', {
            message: error,
            color: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeCard(card, index) {
      const _removeCard = {
        id: card.id
      };

      return api.CardConnect.removeCard(_removeCard)
        .then((result) => {
          this.$store.commit('showSnackbar', {
            message: 'Card removed successfully',
            color: 'success'
          });
          this.cards.splice(index, 1);
          this.getCardConnectProfile();
          this.$emit('refetchCards');
        })
        .catch((error) => {
          this.$store.commit('showSnackbar', {
            message: error,
            color: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setDefault(card, index) {
      this.$modal.show({
        text: `Do you want to make card ${card.account} the default?`,
        buttons: [
          {
            text: 'Cancel',
            color: 'grey black--text',
            onAction: () => {
              this.listingCards[index].is_default = false;
              return;
            }
          },
          {
            text: 'Yes',
            color: 'primary white--text',
            onAction: async () => {
              this.updateCardDefault(card, index);
            }
          }
        ]
      });
    },
    getCardConnectProfile() {
      this.loading = true;
      this.cards = [];
      api.CardConnect.getCardConnectProfile()
        .then(async (res) => {
          if (res.length > 0) {
            const cardProfiles = [...res];
            cardProfiles.forEach((cardProfile) => {
              this.cards.push({
                ...cardProfile,
                expiry: cardProfile.expires,
                account:
                  cardProfile.token.substr(1, 2) +
                  'XX XXXX XXXX ' +
                  cardProfile.last_four,
                cardType: cardProfile.description,
                lastfour: cardProfile.last_four
              });
            });
          } else {
            this.showAddCard = true;
          }
        })
        .catch((err) => alert(err))
        .finally(() => {
          this.loading = false;
          this.keyHeaderCryo = new Date().getTime();
        });
    }
  }
};
</script>

<style scoped>
.v-text-field {
  margin: 5px;
}
</style>
